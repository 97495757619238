var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-md-4 mb-2",attrs:{"id":"kt_profile_aside"}},[_c('div',{staticClass:"card card-custom card-stretch"},[_c('div',{staticClass:"card-body pt-4"},[_c('div',{staticClass:"d-flex align-items-center mt-4"},[_c('div',{staticClass:"symbol symbol-circle symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center"},[_c('div',{staticClass:"symbol-label symbol-circle",style:([
                {
                  'background-image': 'url(' + _vm.avatarUrl + ')'
                }
              ])}),_c('i',{staticClass:"symbol-badge bg-success"})]),_c('div',[_c('a',{staticClass:"font-weight-bolder font-size-h5 text-dark-75 text-hover-primary",attrs:{"href":"#"}},[_vm._v(" "+_vm._s(_vm.currentAccount !== null ? _vm.currentAccount.firstName : null)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.currentAccount !== null ? _vm.currentAccount.lastName : null)+" ")])])]),_c('div',{staticClass:"py-9"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between mb-2"},[_c('span',{staticClass:"font-weight-bold mr-2"},[_vm._v("Username:")]),_c('span',{staticClass:"text-muted text-hover-primary"},[_vm._v(_vm._s(_vm.currentAccount !== null ? _vm.currentAccount.username : null))])]),_c('div',{staticClass:"d-flex align-items-center justify-content-between mb-2"},[_c('span',{staticClass:"font-weight-bold mr-2"},[_vm._v("Email:")]),_c('span',{staticClass:"text-muted text-hover-primary"},[_vm._v(_vm._s(_vm.currentAccount !== null ? _vm.currentAccount.email : null))])]),_c('div',{staticClass:"d-flex align-items-center justify-content-between mb-2"},[_c('span',{staticClass:"font-weight-bold mr-2"},[_vm._v("Balance:")]),_c('span',{staticClass:"text-muted text-hover-primary"},[_vm._v(" "+_vm._s(_vm.currentAccount !== null ? _vm.currentAccount.balance : null)+" "+_vm._s(_vm.currency)+" ")])])]),_c('div',{staticClass:"navi navi-bold navi-hover navi-active navi-link-rounded"},[_c('div',{staticClass:"navi-item mb-2"},[_c('router-link',{attrs:{"to":{ name: 'profile-overview' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('a',{class:[
                  'navi-link py-4',
                  isActive && 'active',
                  isExactActive && 'active'
                ],attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"navi-icon mr-2"},[_c('span',{staticClass:"svg-icon"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Design/Layers.svg"}})],1)]),_c('span',{staticClass:"navi-text font-size-lg"},[_vm._v(" "+_vm._s(_vm.$t("Profile Overview"))+" ")])])]}}])})],1),_c('div',{staticClass:"navi-item mb-2"},[_c('router-link',{attrs:{"to":{ name: 'personal-information' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var navigate = ref.navigate;
                var isActive = ref.isActive;
                var isExactActive = ref.isExactActive;
return [_c('a',{class:[
                  'navi-link py-4',
                  isActive && 'active',
                  isExactActive && 'active'
                ],attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"navi-icon mr-2"},[_c('span',{staticClass:"svg-icon"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/General/User.svg"}})],1)]),_c('span',{staticClass:"navi-text font-size-lg"},[_vm._v(" "+_vm._s(_vm.$t("Personal Information"))+" ")])])]}}])})],1),_c('div',{staticClass:"navi-item mb-2"},[_c('router-link',{attrs:{"to":{ name: 'change-password' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var navigate = ref.navigate;
                var isActive = ref.isActive;
                var isExactActive = ref.isExactActive;
return [_c('a',{class:[
                  'navi-link py-4',
                  isActive && 'active',
                  isExactActive && 'active'
                ],attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"navi-icon mr-2"},[_c('span',{staticClass:"svg-icon"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Communication/Shield-user.svg"}})],1)]),_c('span',{staticClass:"navi-text font-size-lg"},[_vm._v(" "+_vm._s(_vm.$t("Change Password"))+" ")])])]}}])})],1)])])])]),_c('div',{staticClass:"col-sm-12 col-md-8 mb-2"},[_c('router-view')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }